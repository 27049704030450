<template>
	<div>
		<s-crud
			:single-row="false"
			ref="gridTransportPayment"
			:filter="filter"
			:config="config"
			add
			addNoFunction
			search-input
			@add="Calculate()"
			title="Calculo de Pago Transporte"
			@rowSelected="rowSelected($event)"
			no-full
			:groupBy="'TrcWeek'"
			height="auto"
		>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">

						<v-col lg="3" md="3" cols="6">
							<s-text
								label="Ingrese Semana"
								v-model="filter.cWeek"
								:min="0"
								number
							></s-text>
						</v-col>

						<v-col lg="3" md="3" cols="6">
							<s-date
								label="Fecha Inicio"
								v-model="filter.cDateInitial"
							></s-date>
						</v-col>

						<v-col lg="3" md="3" cols="6">
							<s-date
								label="Fecha Fin"
								v-model="filter.cDateFin"
							></s-date>
						</v-col>

						<!-- <v-col lg="3" md="3" cols="6" v-if="filter.xCheckPlate == 0">
							<s-select-definition
								v-model="filter.TypeCultive"
								:def="1173"
								id="DedValue"
								label="Cultivo"
							></s-select-definition>
						</v-col> -->

						<!-- <v-col lg="3" md="3" cols="6" v-if="filter.xCheckPlate == 0">
							<s-select-variety
								:cultiveID="filter.TypeCultive"
								label="Variedad"
								v-model="filter.VrtID"
							>
							</s-select-variety>
						</v-col> -->

						<v-col lg="3" md="3" cols="6" >
							<s-select-transport
								label="Placa"
								full
								v-model="filter.TptEnrollment"
								clearable
							>
							</s-select-transport>
						</v-col>

						<!-- <v-col lg="3" md="3" cols="6">
							<s-select-definition
								v-model="filter.TypeLoad"
								:def="1229"
								label="Carga"
							></s-select-definition>
						</v-col>  -->

						<!-- <v-col cols="6" md="6" lg="3">
							<s-switch
								label="Por Placa"
								v-model="filter.xCheckPlate"
							></s-switch>
						</v-col> -->
					</v-row>
				</v-container>
			</template>

			<template v-slot:TrcJabasHarvestedAditional={row}>
				<v-chip x-small :color="row.TrcJabasHarvestedAditional == -1 ? 'error': ''">{{row.TrcJabasHarvestedAditional == -1 ? 'Asigne Canter | Fusso' : row.TrcJabasHarvestedAditional}}</v-chip>
			</template>

			<template v-slot:TrcJabasHarvested={row}>
				<v-chip small :color="row.TrcJabasHarvested > 0 ? 'info': ''">{{row.TrcJabasHarvested}}</v-chip>
			</template>
		</s-crud>
	</div>
</template>
<script>
	//Service
	import _sTransportPaymentService from "../../../services/Technicalassistance/TransportPaymentService";
	

	//Component
	import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety.vue";

	export default {
		components: { SSelectVariety, SSelectTransport },
		data: () => ({
			filter: {
				cDateInitial: null,
				cDateFin: null,
				TypeCultive: 0,
				VrtID: 0,
				//xCheckPlate: 1,
				TptEnrollment: null,
				TypeLoad: 0,
				cWeek: null
			},

			selected: [],
			config:{
				service: _sTransportPaymentService,
					model: {
						ID: "ID",
						TrcDateReception: "date",
						TrcJabasHarvestedAditional: "",
						TrcJabasHarvested: ""
					},
					headers: [
						{ text: "Mes", value: "TrcMounth", style: "min-width: auto" },
						{ text: "Semana", value: "TrcWeek" },
						{
							text: "Fec. Recepcion",
							value: "TrcDateReception",
							width: 100,
						},
						{ text: "Carga", value: "TypeCosteName", width: 150 },
						{ text: "Destino", value: "TypeDestinyName", width: 150 },
						{ text: "Tipo Cultivo", value: "TypeCropName", width: 150 },
						{ text: "Tipo Vehiculo", value: "TypeVehicleBrandName", width: 120 },
						{ text: "Placa", value: "TrcPlate", width: 120 },
						{
							text: "Razon Social",
							value: "TrcReasonSocial",
							width: 200,
						},
						{ text: "Ruc", value: "TrcRuc", width: 120 },
						{ text: "Capac. Min", value: "TrcCapacityMin", width: 50 },
						{ text: "Capac. Max", value: "TrcCapacityMax", width: 50 },
						{ text: "Rango", value: "TrcRange", width: 50 },
						{
							text: "Total Jabas Cosechadas",
							value: "TrcJabasHarvested",
						},
						{
							text: "Cant. Jabas Adicionales",
							value: "TrcJabasHarvestedAditional",
						},
						{ text: "Zona", value: "ZonName", width: 120 },
						{ text: "Fundo/Lote", value: "FltName", width: 320 },
						{ text: "Direccion", value: "FagNameAddress", width: 250 },
						{ text: "Productor", value: "PrdName", width: 280 },
						{
							text: "Nro. Guia Cosecha",
							value: "TrcNumberHarvestGuide",
							width: 200,
						},
						{text: "Nro. Guia Remision", value: "TrcNumberReferralGuide", width: 200},
						{text: "Nro. Guia Remision Transportista", value: "TrcNumberFerralGuideDriver", width: 200},
						{text: "Nro. Guia Remision Mat.", value: "TrcGngNumberGuideMaterials", width: 200},
						{text: "Nro. Guia Remision Mat. Remitente", value: "TrcGngNumberGuideRemitente", width: 200},
						
						{ text: "Tipo carga", value: "TypeChargeName", width: 50 },
						{ text: "Linea negocio", value: "BslName", width: 150 },
						{ text: "Centro costo", value: "CceName", width: 150 },
						{ text: "Cuenta contable", value: "CsaAccountName", width: 200 },
						{ text: "Distancia (KM)", value: "TrcDistance", width: 50 },
						/* { text: "Tipo Carga", value: "TypeChargeName", width: 100 }, */
					],
			}
		}),

		methods: {
			rowSelected(item) {
				this.selected = item;
                console.log("🚀 ~ file: TransportPaymentNew.vue ~ line 190 ~ rowSelected ~ this.selected", this.selected)
			},

			/* masCercano(num) {
				var numeros = [400, 400, 600, 600]
				let cercano = 0;
				let diferencia = 9999999; //inicializado valor máximo de variable de tipo int
				for (let i = 0; i < numeros.length; i++) {
					if (numeros[i] == num) {
						return numeros[i];
					} else {
						if(Math.abs(numeros[i]-num)<diferencia){
							cercano=numeros[i];
							diferencia = Math.abs(numeros[i]-num);
						}
					}
				}
				return cercano;
			}, */

			Calculate() {
				
				
				//Detalles
				let sum = 0
				let exist = false
				let may = 0;
				this.selected.forEach(element => {
					//console.log(this.masCercano(element.TrcJabasHarvested));
					if(element.TrcDistance > may){
						may = element.TrcDistance;
					}
					sum += element.TrcJabasHarvested
					element.TrcID = 0,
					element.SecStatus = 1
					element.UsrCreateID = this.$fun.getUserID();
					//element.may = may;

					if(element.TrcJabasHarvestedAditional == -1){
						exist = true
						this.$fun.alert("Asigne tipo de Vehiculo a la Placa: " + element.TrcPlate, "warning")
					}
					
				});


				if(exist == false){

				
					//Cabecera
					let item = {
						PymID: 0,
						PymHigherDistance: may,
						PymJabasHarvested: sum,
						SecStatus: 1,
						UsrCreateID: this.$fun.getUserID(), 
						Detail: this.selected
					}

					if(item.Detail.length == 0) {
						this.$fun.alert("No existen registros", "warning")
						return;
					}
					console.log("Guardar",item);


					this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
						if (val.value) {
						_sTransportPaymentService
							.savePayment(item, this.$fun.getUserID())
							.then((r) => {
								if(r.status == 200){
									this.$fun.alert("Guardado Correctamente", "success");
									this.$refs.gridTransportPayment.refresh();
								}
								
							});
						}
					});
				}
				
			},
		},
	};
</script>
