import Service from "../Service";
import Vue from "vue";
const resource = "/TransportPrice/";

export default {
    /* GetAre() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetAre";
    }, */
    pagination(parameters, requestID) {
        return Service.post(resource + "paginationPayment", parameters, {
            params: { requestID: requestID },
        });
    },

    savePayment(obj, requestID) {
        return Service.post(resource + "savePayment", obj, {
            params: { requestID: requestID },
        });
    },

};