<template v-slot:no-data>
	<v-card dense>
		<v-row style="margin-top: 4px;">
			<v-col cols="12" lg="12" md="12">
				<v-tabs v-model="currentItem">
					<v-tab href="#CalcPayment"> Calcular Pago</v-tab>
					<v-tab href="#Payment"> Pagos</v-tab>
				</v-tabs>

				<v-tabs-items v-model="currentItem">
					<v-tab-item :value="'CalcPayment'">
						<transport-payment-new />
					</v-tab-item>

					<v-tab-item :value="'Payment'">
						<transport-payment-calculate />
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>
	//Component
	import TransportPaymentCalculate from "./TransportPaymentCalculate.vue";
	import TransportPaymentNew from "./TransportPaymentNew.vue";

	export default {
		components: { TransportPaymentCalculate, TransportPaymentNew },
		props: {},
		data: () => ({
			currentItem: "tab-Funciones",
		}),
	};
</script>
