<template>
	<v-card dense>
		<v-col cols="12" style="margin-top: -12px">
			<s-toolbar
				color="#999999"
				dark
				@close="close()"
				:save="item.PymStatus == 1"
				@save="save()"
				close
				:label="
					'Resumen Pago - Orden de Compra ==> ' +
						item.OrdDocumentNumber
				"
			/>
		</v-col>
		<v-row style="margin: auto">
			<v-col cols="12" lg="5" md="5" v-if="item.PymStatus == 1">
				<s-supplier label="Proveedor" v-model="Supplier"></s-supplier>
			</v-col>

			<v-col cols="12" lg="5" md="5" v-if="item.PymStatus == 2">
				<s-text label="Proveedor" v-model="item.SupName"> </s-text>
			</v-col>

			<v-col lg="3" md="3" cols="12">
				<s-contact-supplier
					label="Contacto"
					:SupID="SupID"
					v-model="item.ConID"
				/>
			</v-col>

			<v-col cols="12" lg="3" md="3">
				<s-date label="Fecha" v-model="dateOrder"></s-date
			></v-col>

			<v-col cols="12" lg="3" md="3"
				><s-select-definition
					:def="1129"
					label="Moneda"
					v-model="item.TypeCurrency"
			/></v-col>

			<v-col cols="12" lg="2" md="2">
				<s-select-Campaign label="Campaña" v-model="item.CmpID"
			/></v-col>

			<v-col cols="12" lg="3" md="3">
				<s-text
					label="N° Cotización"
					:maxlength="20"
					v-model="item.QuoteNumber"
				/>
			</v-col>

			<v-col cols="12" lg="3" md="3">
				<s-select-definition
					label="Clase de Operación"
					v-model="item.ClassOperationPurchase"
					:def="1147"
				/>
			</v-col>

			<v-col cols="12">
				<v-data-table
					item-key="TrcID"
					dense
					:items="itemDetail"
					:headers="headers"
					:items-per-page="-1"
					hide-default-footer
					v-model="selected"
					@click:row="rowClick"
					height="220"
				>
					<template v-slot:item.TrcDescription="props">
						<div>
							<s-text
								:maxlength="100"
								autofocus
								v-model="props.item.TrcDescription"
							></s-text>
						</div>
					</template>

					<template v-slot:item.OddUnitPrice="props">
						<s-text
							style="width: 75px"
							decimal
							v-model="props.item.OddUnitPrice"
							@input="changeItem(props.item)"
						>
						</s-text>
					</template>

					<template v-slot:item.TrcPriceTotal="props">
						<v-chip small :color="'success'">
							{{ props.item.TrcPriceTotal }}
						</v-chip>
					</template>
					<template v-slot:item.Options="props">
						<v-btn
							v-if="item.PymStatus == 1"
							@click="removeItem(props.item)"
							x-small
							color="error"
							elevation="0"
							block
						>
							<v-icon style="font-size: 16px !important"
								>fas fa-times</v-icon
							>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>

			<v-col lg="3">
				<v-row>
					<v-col lg="6" class="s-col-form"
						><v-label>SUB-TOTAL</v-label></v-col
					>
					<v-col lg="6" class="s-col-form"
						><h4>
							{{
								item.OrdSubTotal
									? item.OrdSubTotal.toFixed(2)
									: "0.00"
							}}
						</h4>
					</v-col>
				</v-row>

				<v-row>
					<v-col class="s-col-form"
						><v-label>IMPUESTO</v-label></v-col
					>
					<v-col class="s-col-form"
						><h4>
							{{
								item.OrdTaxAmount
									? item.OrdTaxAmount.toFixed(2)
									: "0.00"
							}}
						</h4></v-col
					>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<v-label>TOTAL</v-label>
					</v-col>
					<v-col class="s-col-form"
						><h4>
							{{
								item.OrdTotal
									? item.OrdTotal.toFixed(2)
									: "0.00"
							}}
						</h4></v-col
					>
				</v-row>
			</v-col>

			<v-col cols="12">
				<v-row>
					<v-col cols="12" lg="3">
						<v-row>
							<v-col cols="10"
								><v-file-input
									label="Anexo 01"
									v-model="Attach1"
									hide-details
							/></v-col>
							<v-col>
								<v-btn
									color="success"
									class="mt-5"
									:disabled="false"
									icon
									x-small
									@click="openFile(item.OrdAttach1)"
								>
									<v-icon>mdi-download</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="12" lg="3">
						<v-row>
							<v-col cols="10"
								><v-file-input
									label="Anexo 02"
									v-model="Attach2"
									hide-details
							/></v-col>
							<v-col>
								<v-btn
									color="success"
									class="mt-5"
									:disabled="false"
									icon
									x-small
									@click="openFile(item.OrdAttach2)"
								>
									<v-icon>mdi-download</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="12" lg="3">
						<v-row>
							<v-col cols="10"
								><v-file-input
									label="Anexo 03"
									v-model="Attach3"
									hide-details
							/></v-col>
							<v-col>
								<v-btn
									color="success"
									class="mt-5"
									:disabled="false"
									icon
									x-small
									@click="openFile(item.OrdAttach3)"
								>
									<v-icon>mdi-download</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="12" lg="3">
						<v-row>
							<v-col cols="10"
								><v-file-input
									label="Anexo 04"
									v-model="Attach4"
									hide-details
							/></v-col>
							<v-col>
								<v-btn
									color="success"
									class="mt-5"
									:disabled="false"
									icon
									x-small
									@click="openFile(item.OrdAttach4)"
								>
									<v-icon>mdi-download</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import _sPaymentCalculateService from "../../../services/Technicalassistance/TransportPaymentCalculateService";
	import _sTransportPaymentService from "../../../services/Technicalassistance/TransportPaymentService";

	import _sGeneral from "@/services/HelperService.js";
	import SSupplier from "@/components/Utils/Logistics/SSupplier.vue";
	import SContactSupplier from "../../../components/Utils/Logistics/SContactSupplier.vue";
	import _sHelper from "@/services/HelperService";

	export default {
		props: {
			items: null
		},

		components: { SSupplier, SContactSupplier },

		data: () => ({
			headers: [
				{ text: "", value: "Options", sortable: false },
				{ text: "Mes", value: "TrcMounth", sortable: false },
				{ text: "Semana", value: "TrcWeek", width: 100, sortable: false },
				{
					text: "Fec. Recepcion",
					value: "TrcDateReception",
					width: 100,
					sortable: false
				},
				{ text: "Costo", value: "TypeCosteName", sortable: false },
				{ text: "Placa", value: "TrcPlate", width: 100, sortable: false },
				{
					text: "Capacidad",
					value: "TrcCapacityJabas",
					width: 100,
					sortable: false
				},
				{
					text: "Jabas Total",
					value: "TrcJabasHarvested",
					width: 100,
					sortable: false
				},
				{
					text: "Adicionales",
					value: "TrcJabasHarvestedAditional",
					sortable: false,
					width: 100
				},
				{
					text: "Precio U.",
					value: "TrcPrice",
					width: 100,
					sortable: false
				},
				{
					text: "Precio adicional",
					value: "TrcPriceAditional",
					width: 100,
					sortable: false
				},
				{
					text: "Precio Total",
					value: "TrcPriceTotal",
					width: 100,
					sortable: false
				},
				{ text: "Zona", value: "ZonName", width: 200, sortable: false },
				{
					text: "Guia Cosecha",
					value: "TrcNumberHarvestGuide",
					width: 150,
					sortable: false
				},
				{
					text: "Guia remision",
					value: "TrcNumberReferralGuide",
					width: 150,
					sortable: false
				},

				{
					text: "Tipo Carga",
					value: "TypeChargeName",
					width: 100,
					sortable: false
				},
				{
					text: "Linea negocio",
					value: "BslName",
					width: 150,
					sortable: false
				},
				{
					text: "Centro costo",
					value: "CceName",
					width: 150,
					sortable: false
				},
				{
					text: "Cuenta contable",
					value: "CsaAccountName",
					width: 200,
					sortable: false
				},
				{
					text: "Descripción",
					value: "TrcDescription",
					sortable: false,
					width: 340
				},
				{
					text: "Inpuesto",
					value: "OddTaxPercentage",
					width: 100,
					sortable: false
				},
				{ text: "Igv", value: "OddTaxAmount", width: 100, sortable: false },
				{
					text: "Total (Incluye Igv)",
					value: "OddUnitPrice",
					width: 100,
					sortable: false
				},
				{
					text: "Sin Igv",
					value: "OddAmount",
					width: 100,
					sortable: false
				},
				{
					text: "Comentario",
					value: "TrcObservation",
					width: 200,
					sortable: false
				}
			],
			Supplier: null,
			SupID: "",
			Attach1: null,
			Attach2: null,
			Attach3: null,
			Attach4: null,

			dateOrder: null,
			item: {},
			itemDetail: [],
			selected: []
		}),


		watch: {
			Supplier() {
				if (this.Supplier != null)
					if (this.Supplier.SupID != null)
						this.SupID = this.Supplier.SupID;
			}
		},

		methods: {
			uploadFile1()
			{
				if(this.Attach1 != null){
					var fileName = this.Attach1.name;
					var formData = new FormData();
					formData.append("file", this.Attach1);
					_sHelper.uploadfile(1, this.$fun.getUserID(), formData, 11).then((response) => {
						if(response.status == 200){
							if(this.Attach2 != null){
								var fileName2 = this.Attach2.name;
								var formData2 = new FormData();
								formData2.append("file", this.Attach2);
								_sHelper.uploadfile(1, this.$fun.getUserID(), formData2, 11).then((response) => {
									if(response.status == 200){
										if(this.Attach3 != null){
											var fileName3 = this.Attach3.name;
											var formData3 = new FormData();
											formData3.append("file", this.Attach3);
											_sHelper.uploadfile(1, this.$fun.getUserID(), formData3, 11).then((response) => {
												if(response.status == 200){
													if(this.Attach4 != null){
														var fileName4 = this.Attach4.name;
														var formData4 = new FormData();
														formData4.append("file", this.Attach4);
														_sHelper.uploadfile(1, this.$fun.getUserID(), formData4, 11).then((response) => {
															if(response.status == 200){
																
															}
														});
													}
												}
											});
										}
									}
								});
							}
						}
					});
				}
				/* else{
					this.$fun.alert("Sin datos que subir");
				} */
			},

			close() {
				
				this.$emit("close");
			},

			orderDetails() {
				let i = 0;
				this.itemDetail.map(e => {
					i++;
					e.Line = i;
				});
			},

			rowClick: function(item, row) {
				this.selected = [item];
			},

			isSum() {
				let sumOrdTotal = 0;
				let OrdTaxAmount = 0;
				let OrdSubTotal = 0;
				this.items.itemsDetail.forEach(element => {
					sumOrdTotal += parseFloat(element.OddUnitPrice)//parseFloat(element.OddAmount);
					OrdTaxAmount += parseFloat(element.OddTaxAmount);
					OrdSubTotal += parseFloat(element.OddAmount)//parseFloat(element.OddUnitPrice);
				});

				this.item.OrdTotal = sumOrdTotal;
				this.item.OrdTaxAmount = OrdTaxAmount;
				this.item.OrdSubTotal = OrdSubTotal;
			},

			changeItem(item) {
				console.log(item);

				this.item.OrdSubTotal = 0;
				this.item.OrdTaxAmount = 0;

				var edit = 0;

				edit = Object.assign(
					{},
					this.itemDetail.find(x => x.Line == item.Line)
				);

				let indexEdit = this.itemDetail.indexOf(item);

				edit.OddQuantity = edit.OddQuantity == null ? 1 : parseFloat(edit.OddQuantity);
				edit.OddTaxAmount = ((edit.OddTaxPercentage == null ? 0 : edit.OddTaxPercentage) / 100) * edit.OddUnitPrice * edit.OddQuantity;
				edit.OddAmount = edit.OddUnitPrice * edit.OddQuantity - (edit.OddTaxAmount == null ? 0 : edit.OddTaxAmount);

				this.itemDetail.splice(indexEdit, 1, edit);
				this.itemDetail.forEach(element => {
					
					
					let unitPrice = element.OddUnitPrice == null || element.OddUnitPrice == ""? 0: element.OddUnitPrice;
					//this.item.OrdSubTotal =parseFloat(this.item.OrdSubTotal) + parseFloat(unitPrice) * (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

					this.item.OrdTaxAmount = parseFloat(this.item.OrdTaxAmount) + unitPrice * parseFloat(element.OddQuantity) * (element.OddTaxPercentage == null ? 0: parseFloat(element.OddTaxPercentage) / 100);
					this.item.OrdSubTotal += element.OddAmount //parseFloat(this.item.OrdSubTotal) + parseFloat(unitPrice) * (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));
				});
				this.item.OrdTotal = this.item.OrdSubTotal + this.item.OrdTaxAmount;
			},

			openFile(nameFile) {
				console.log("donwww ", nameFile);
				_sHelper.getfile(1, this.$fun.getUserID(), nameFile, 11).then((r) => {
					const url = window.URL.createObjectURL(new Blob([r.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", nameFile);
					document.body.appendChild(link);
					link.click();
				});
				/* _sGeneral.download(url.name, this.$fun.getUserID()).then(r => {
					this.$fun.downloadHttp(r);
				}); */
			},

			save() {
				if (this.item.PymStatus == 2) {
					this.$fun.alert(
						"Registro ya se encuentra registrado como Orden de Compra",
						"warning"
					);
					return;
				}
				if (this.Supplier == null) {
					this.$fun.alert("Falta proveedor", "warning");
					return;
				}

				if (this.Attach1 == null) {
					this.$fun.alert("Falta adjuntar archivo", "warning");
					return;
				}

				this.item.TypeOrder = 3;
				this.item.TypePurchase = 1;
				this.item.OfficePurchase = 1; //Encargardo - Tambogrande
				this.item.CalculateTransport = 10; //Como parametro para luaego actualiar en el procedure

				this.item.SupCode = this.Supplier.SupID;
				this.item.OrdAttach1 =
					this.Attach1 != null ? this.Attach1.name : "";
				this.item.OrdAttach2 =
					this.Attach2 != null ? this.Attach2.name : "";
				this.item.OrdAttach3 =
					this.Attach3 != null ? this.Attach3.name : "";
				this.item.OrdAttach4 =
					this.Attach4 != null ? this.Attach4.name : "";
				this.item.CardName = this.Supplier.SupName;
				this.item.OrdDate = this.dateOrder;
				
				//let DistanceHigher = 0;
				this.items.itemsDetail.forEach(element => {
					/* if(element.TrcDistance > DistanceHigher){
						DistanceHigher = element.TrcDistance;
					} */
					element.AcoName = element.CsaDescription;
					element.OddAccount = element.CsaAccount;
					element.OddDescription = element.TrcDescription;
					element.OddObservation = element.TrcObservation;
					element.TypeCurrency = this.item.TypeCurrency;
					element.UsrCreateID = this.$fun.getUserID();
					//element.DistanceHigher = DistanceHigher;
				});

				this.item.Detail = this.items.itemsDetail;

				console.log("Guardar", this.item);
				//console.log("mayorr ", DistanceHigher);

				this.$fun.alert("Seguro de guardar?", "question").then(r => {
					if (r.value) {
						_sPaymentCalculateService
							.saveOrderPurchase(this.item, this.$fun.getUserID(), 0)
							.then(r => {
								if (r.status == 200) {
									console.log(r.data);
									this.item.OrdID = r.data[0].OrdID;

									_sTransportPaymentService
										.savePayment(
											this.item,
											this.$fun.getUserID()
										)
										.then(r => {
											if (r.status == 200) {
												this.$fun.alert(
													"Se actualizaron datos correctamente",
													"success"
												);
												this.uploadFile1();
												this.close();
											}
										});
								}
							});
					}
				});
			},

			removeItem(item){
				console.log("removeItem", item);

				this.$fun.alert("¿Seguro anular item?", "question").then(val => {
                    if(val.value){
                        _sPaymentCalculateService.saveAditional(item, this.$fun.getUserID()).then(resp => {
                            if(resp.status == 200)
								this.close()
                        })
                    }
                })

			}
		},

		created() {
			
			this.item = this.items;

			this.itemDetail = this.items.itemsDetail;
			
			if (this.item.SupCode !== null) {
				this.SupID = this.item.SupCode;
			}
			
			this.orderDetails();
			this.isSum();
		},

	};
</script>

