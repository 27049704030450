<template>
	<v-card rounded shaped>
		<v-col cols="12" style="margin-top: -12px">
			<s-toolbar
				color="#999999"
				dark
				@close="close()"
				save
				@save="save()"
				close
				:label="'Pagos Adicionales'"
			/>
		</v-col>
		<v-row style="margin: auto" justify="center" >
			<v-col lg="3" md="3" cols="12" class="pb-0 mt-0">
				<!-- Anterior 1199 -->
				<s-select-definition
					v-model="item.TypeShip"
					:def="1342"
					label="Nave"
				>
				</s-select-definition>
			</v-col>

			<v-col lg="3" md="3" cols="12" class="pb-0 mt-0">
				<!-- Anterior 1199 -->
				<s-select-definition
					v-model="item.TypeCoste"
					:def="1229"
					label="Costo"
				>
				</s-select-definition>
			</v-col>

			<v-col lg="3" md="3" cols="12" class="pb-0 mt-0">
				<s-text label="Precio" ref="price" decimal v-model="item.TrcPrice"></s-text>
			</v-col>

            <v-col lg="4" md="4" cols="12" class="pb-0 mt-0">
				<s-text label="N° Guia Cosecha" v-model="item.TrcNumberHarvestGuide"></s-text>
			</v-col>

            <v-col lg="5" md="5" cols="12" class="pb-0 mt-0">
				<s-text label="N° Guia Remision" ref="NumberReferralGuide" v-model="item.TrcNumberReferralGuide"></s-text>
			</v-col>

            <v-col lg="9" md="9" cols="12" class="pb-3 mt-0">
				<s-textarea label="Descripcion" v-model="item.TrcObservation"></s-textarea>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>

    import _sPaymentCalculate from "../../../services/Technicalassistance/TransportPaymentCalculateService";

	export default {
		props: {
			items: null
		},

		components: {},

		data: () => ({
			item: {}
		}),

		created() {
			console.log("items", this.items);
		},

		watch: {},

		methods: {
			close() {
				this.$emit("closeAditional");
			},

			save() {

                if(this.item.TypeCoste == null){
                    this.$fun.alert("seleccione tipo de costo", "warning")
                    return;
                }

                if(this.item.TypeShip == null){
                    this.$fun.alert("seleccione nave", "warning")
                    return;
                }

                if(this.item.TrcPrice == null || this.item.TrcPrice <= 0){
                    //this.$fun.alert("Ingrese Precio", "warning")
                    this.$refs.price.error("Ingrese Precio")
                    return;
                }

                if(this.item.TrcNumberReferralGuide.length == 0){
                    //this.$fun.alert("Ingrese guia de remision", "warning")
                    this.$refs.NumberReferralGuide.error("Ingrese guia de remision")
                    return;
                }

                this.item.TrcID = 0
                this.item.PymID = this.items.PymID;
                this.item.TrcPlate = this.items.PymPlate
                this.item.SecStatus  =1 
                this.item.UsrCreateID = this.$fun.getUserID();
                this.item.TrcWeek = this.items.PymWeek

                console.log("Guardar", this.item);
            
                this.$fun.alert("¿Seguro de Guardar?", "question").then(val => {
                    if(val.value){
                        _sPaymentCalculate.saveAditional(this.item, this.$fun.getUserID()).then(resp => {
                            if(resp.status == 200)
                                this.close()
                        })
                    }
                })
                

            }
		}
	};
</script>

<style></style>
