<template v-slot:no-data>
	<div>
		<s-crud
			ref="gridPaymentCalculate"
			:filter="filter"
			:config="config"
			add
			:remove="selected.PymStatus == 1"
			@add="openModal()"
			addNoFunction
			@rowSelected="rowSelected($event)"
			no-full
			:groupBy="'PymWeek'"
			height="auto"
			
		>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">

						<v-col lg="3" md="3" cols="12">
							<s-date
								label="Initial date"
								v-model="filter.cDateInitial"
							></s-date>
						</v-col>

						<v-col lg="3" md="3" cols="12">
							<s-date
								label="End date"
								v-model="filter.cDateFin"
							></s-date>
						</v-col>

						<v-col lg="3" md="3" cols="12">
							<s-select-transport
								clearable
								label="Placa"
								full
								v-model="filter.TptEnrollment"
							>
							</s-select-transport>
						</v-col>

						<v-col lg="3" md="3" cols="12" class="mt-4" v-if="selected.PymID > 0 && selected.PymStatus == 1">
							<v-btn small rounded color="info" @click="clickAditional()"> Pagos adicionales</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:PymStatus="{ row }">
				<v-chip
					:color="row.PymStatus == 1 ? 'error': 'success'"
					x-small
				>
				{{row.PymStatus == 1 ? 'Sin enviar' : 'Enviado logistica'}}
				</v-chip>
			</template>
		</s-crud>

		<v-dialog v-if="dialog" v-model="dialog" persistent transition="dialog-bottom-transition">
			<transport-payment-edit 
				:items="selected" 
				@close="close"   
			/>
		</v-dialog>

		<v-dialog v-if="openAditional" v-model="openAditional" persistent transition="dialog-bottom-transition" width="50%">
			<payment-aditional @closeAditional="closeAditional()" :items="selected"></payment-aditional>
		</v-dialog>
	</div>
</template>
<script>
	//Service
	import _sPaymentCalculate from "../../../services/Technicalassistance/TransportPaymentCalculateService";
	import _sPayment from "../../../services/Technicalassistance/TransportPaymentService";
	import TransportPaymentNew from "./TransportPaymentNew.vue";
	import TransportPaymentEdit from "./TransportPaymentEdit.vue";
	import PaymentAditional from "./PaymentAditional.vue";

	import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";

	export default {
		components: {
			TransportPaymentNew,
			TransportPaymentEdit,
			SSelectTransport,
			PaymentAditional,
		},
		data: () => ({
			filter: {
				cDateInitial: "",
				cDateFin: "",
				TptEnrollment: "",
			},

			dialog: false,
			openAditional: false,
			selected: {},

			config: {
				service: _sPaymentCalculate,
				model: {
					PymID: "ID",
					PymPrice: "",
					PymPriceAditional: "",
					PymStatus:""
				},
				headers: [
					{ text: "ID", value: "PymID" },
					{ text: "Mes", value: "PymMounth" },
					{ text: "Semana", value: "PymWeek" },
					{ text: "Placa", value: "PymPlate" },
					{ text: "Jabas", value: "PymJabasHarvested" },
					{ text: "Adicionales", value: "PymJabasHarvestedDifference" },
					{ text: "Jabas Total", value: "PymJabasHarvestedTotal" },
					{ text: "Precio", value: "PymPrice" },
					{ text: "Precio adicional", value: "PymPriceAditional" },
					{ text: "Precio total", value: "PymPriceTotal" },
					{ text: "Estado", value: "PymStatus" },
				],
			},
		}),

		methods: {

			rowSelected(item) {
				this.selected.itemsDetail = []
				if(item !== undefined) {
					if(item.length > 0){
						this.selected = item[0];

						_sPaymentCalculate.GetRecord(this.selected.PymID, this.$fun.getUserID()).then( r => {
							if(r.status == 200){
								
								r.data.forEach(element => {
									element.TrcDateReception = this.$moment(element.TrcDateReception).format(this.$const.FormatDateDB);
								});
								this.selected.itemsDetail = r.data
								
							}
						});
					}else{
						this.selected.PymID = 0
					}
					
				}
				
			},

			clickAditional(){
				this.openAditional = true
			},

			closeAditional(){
				this.openAditional = false
				this.$refs.gridPaymentCalculate.refresh();
			},

			close() {
				this.dialog = false;
				this.$refs.gridPaymentCalculate.refresh();
			},

			openModal(){
				if(this.selected.itemsDetail.length > 0){
					this.dialog = true
				}
				
				
			}
		},
	};
</script>
