import Service from "../Service";
import Vue from "vue";
const resource = "/TransportPrice/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationPaymentCalculate", parameters, {
            params: { requestID: requestID },
        });
    },

    GetRecord(PymID, requestID) {
        return Service.post(resource + "GetRecord", "", {
            params: { PymID: PymID, requestID: requestID },
        });
    },

    /* updatePayment(obj, requestID) {
        return Service.post(resource + "updatePayment", obj, {
            params: { requestID: requestID },
        });
    }, */


    //SOLO LO UTILIZO PARA ELIMINAR REGISTRO
    save(obj, requestID) {
        return Service.post(resource + "savePayment", obj, {
            params: { requestID: requestID },
        });
    },

    saveOrderPurchase(ord, requestID, AccID) {
        return Service.post("orderpurchase/" + "save", ord, {
            params: { requestID: requestID, AccID: AccID },
        });
    },

    saveAditional(obj, requestID) {
        return Service.post(resource + "savePaymentAditional", obj, {
            params: { requestID: requestID },
        });
    },

















    /* saveRequirement(cmp, requestID, AccID) {
        if (!AccID)
            return Service.post("Requirement/save", cmp, {
                params: { requestID: requestID },
            });
        else return Service.post("Requirement/saveT", cmp, {
            params: { requestID: requestID, AccID: AccID },
        });
    }, */



};